/**
 * 示例-自定义组件-超链接
 * luxinwen
 * 2023-04
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">sp-linkto 超链接</div>
        <div class="page-main-content-subhead">示例</div>
        <Card class="page-main-content-item">
          <sp-linkto v-model="value" :options="options" class="width-m" @query="queryData"></sp-linkto>
          <Divider orientation="left" plain>基本用法</Divider>
          <pre>&lt;sp-linkto v-model="value" :options="options"&gt;&lt;/sp-linkto&gt;</pre>
          <p>基本用法。可以使用 v-model 双向绑定数据。</p>
          <p><span class="text-red">options</span> 传入下拉列表项数组，数组元素可用 string 格式匹配预设项，也可用 array 格式新增下拉项。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-linkto v-model="value" :options="options" class="width-m" disabled></sp-linkto>
          <Divider orientation="left" plain>禁用状态</Divider>
          <pre>&lt;sp-linkto v-model="value" :options="options" disabled&gt;&lt;/sp-linkto&gt;</pre>
          <p>设置属性 <span class="text-red">disabled</span>，可以禁用组件。</p>
        </Card>
        <div class="page-main-content-subhead">Props</div>
        <Table :columns="propsColumns" :data="propsData" border />
        <div class="page-main-content-subhead">Events</div>
        <Table :columns="eventsColumns" :data="eventsData" border />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        params: {
          'value': {
            tips: '当前超链接数据，可以使用 v-model 双向绑定数据',
            type: 'String',
            default: ''
          },
          'options': {
            tips: '可跳转下拉列表项，数组元素可用 string 格式匹配预设项，也可用 array 格式新增下拉项',
            type: 'Array',
            default: '[]'
          },
          'disabled': {
            tips: '是否禁用',
            type: 'Boolean',
            default: 'false'
          },
          'size': {
            tips: '显示规格，可选值 default / small',
            type: 'String',
            default: 'default'
          }
        },
        events: {
          'query': {
            tips: '点击查询按钮的回调，返回当前 type 和 value',
            return: 'type：跳转类型；value：输入值'
          }
        },
        propsColumns: [
          {
            title: '属性',
            key: 'name',
            width: 140
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '类型',
            key: 'type',
            width: 140
          },
          {
            title: '默认值',
            key: 'default',
            width: 160
          }
        ],
        eventsColumns: [
          {
            title: '事件名',
            key: 'name',
            width: 250
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '返回值',
            render: (h, params) => {
              let children = [];
              params.row.return.split('；').forEach(item => {
                let p = [];
                item.split('：').forEach((key, index) => {
                  p.push(h('span', {
                    attrs: {
                      class: index === 0 ? 'text-red' : ''
                    }
                  }, key));
                });
                if (p.length === 2) {
                  p.splice(1, 0, h('span', '：'));
                }
                children.push(h('p', p));
              });
              return h('div', children);
            }
          }
        ],
        value: '',
        options: ['web', 'goods', {
          type: 'custom',
          label: '自定义',
          data: [
            {
              value: 'value1',
              prefix: '输入值1'
            },
            {
              value: 'value2',
              prefix: '输入值2',
              button: '查询数据'
            }
          ]
        }]
      };
    },
    computed: {
      propsData() {
        let val = [];
        let data = this.params;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          item.default = item.default || '-';
          val.push(item);
        });
        return val;
      },
      eventsData() {
        let val = [];
        let data = this.events;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          val.push(item);
        });
        return val;
      }
    },
    methods: {
      /**
       * 查询按钮回调事件
       */
      queryData(res) {
        console.log('query', res);
      }
    }
  };
</script>